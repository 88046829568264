import { Grid } from "@mui/material";
import { HomeRoom } from "../../img/Icons";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { removeSSItems } from "../../util/storage";
import { SS_PREF as SS_CDS } from "../Home/Components/CDS";
import { SS_PREF as SS_MYAPPS } from "../MyApps/MyApps";
import { dcpsConfig } from "../../util/Services";
const Login = () => {

  const { instance } = useMsal();
  const { t } = useTranslation();

  useEffect(() => {
    handleLogin(instance);
  }, [instance]);

  const handleLogin = (instance: any) => {
    instance
      //.loginRedirect(loginConfig)
      .loginRedirect(dcpsConfig)

      .catch((e: any) => {
      });
  };

  // Clear session storage so items re-load
  useEffect(() => {
    const ss_cds = new RegExp(SS_CDS + "*");
    const ss_myApps = new RegExp(SS_MYAPPS + "*");
    removeSSItems(ss_cds);
    removeSSItems(ss_myApps);
  }, []);

  return (
    <Grid container className={"loginGrid"} data-file="Login">
      <div className={"loginGridDiv"}>
        <div className={"headerTitle"}>
          <HomeRoom width="100%" height="100%" />
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            size="medium"
            color="primary"
            startIcon={<PersonIcon />}
            onClick={() => handleLogin(instance)}
          >
            <b>{t("Login.Sign In")}</b>
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default Login;
