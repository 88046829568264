// page purpose: expose important information that is hidden in some cases

import { Result, iTry, isOkResult, resultError, resultOk } from "../Reusable";
import { UserInterface } from "../adapters/ApiSchema";
import { HomeRoom } from "../img/Icons";
import { createLSFeatureFlag, getFromLS } from "../util/storage";

import { contentSearchFeatureFlagKey } from "../components/appBar/ContentSearch";
import { useDynamicNavFeatureFlagKey } from "../util/Globale";
import { doAuthDiagFeatureFlagKey } from "../components/appBar/AppNavBar";
import { queryKnownMatchMedia } from "../adapters/windowUtils";
import { dump } from "../components/DumpComponent";

let tryDisplayFeatureFlagsKey = "diagFF";
let tryDisplayFeatureFlags = createLSFeatureFlag(tryDisplayFeatureFlagsKey, true, true);

// https://stackoverflow.com/questions/32696475/typescript-tsx-and-generic-parameters
// let iTryAsync = <T,>(f: () => Promise<T>) : Promise<T> => async {
let iTryAsync = async function <T>(f: () => Promise<Result<T, unknown>>) {
    try {
        let result = await f();
        return resultOk(result);
    } catch (ex) {
        return resultError(ex);
    }
};

let iTryDisplay = <T,>(f: () => T) => {
    let result = iTry(f);
    return JSON.stringify(isOkResult(result) ? result.value : result.error);
};
// TODO: make a function to safely try to import all the feature flags and get their current value
// let tryImportFeatureFlags = async () => {
//     return iTry(async () => {


//     });
// }

// particularly in things like the wrapper app that hides the entire console.
export function DiagPage(props: { user: UserInterface | undefined }) {
    let ffText: string | undefined = undefined;
    let isSmallDevice = iTryDisplay(() => queryKnownMatchMedia('isSmallDevice') ?? false);
    console.log('mql', isSmallDevice);

    if (tryDisplayFeatureFlags.getter() === true) {
        let ffMap: any = {};
        [contentSearchFeatureFlagKey, doAuthDiagFeatureFlagKey, tryDisplayFeatureFlagsKey, useDynamicNavFeatureFlagKey].sort().map(key => iTryDisplay(() => ffMap[key] = JSON.stringify(getFromLS(key))));
        console.log(ffMap);
        // ffText = JSON.stringify(ffMap, (key, value) => (
        //     value ?? "undefined"
        // ), 4);
    }
    return (<div>
        Welcome to diag!
        <ul>
            <li key="upnOverride">Override upn:{iTryDisplay(() => getFromLS<string>('upn'))}</li>
            <li key="matchMedia600">isSmallDevice: {isSmallDevice}</li>
            <li key="ua">UserAgent: {iTryDisplay(() => window.navigator.userAgent)}</li>
            <li key="userDump">User: {props.user ? dump(true, props.user) : "none"}</li>
            <li key="user">User: <pre>{props.user ? JSON.stringify(props.user, null, 4) : "none"}</pre></li>
            <li key="ff">FeatureFlags: <pre>{ffText}</pre></li>
            <li key="img"><img alt="author thumbnail" onLoad={e => console.warn('I am loaded!', e.target)} className="CDS-hover css-1bvi6x3" src="https://dcps-core-api-dev.azurewebsites.net/api/dcps/contentStream?action=image&amp;provider=CDSProvider&amp;id=fae58463-af16-4b08-ab12-6eaa78af63d2" loading="lazy" /></li>
        </ul>
        <HomeRoom />
    </div>)
}