

import {
    useCallback,
    useRef,
    useState,
} from "react";

import {
    Paper,
    InputBase
} from '@mui/material';

import IconButton from '@mui/material/IconButton';

import {
    Search as SearchIcon,
    HighlightOff as XIcon,
    // SearchOff as XSearchIcon
} from '@mui/icons-material';


import { createLSFeatureFlag } from "../../util/storage";
import { System } from "../../Reusable";
import { queryKnownMatchMedia } from "../../adapters/windowUtils";
import { withPreventDefault } from "../../adapters/Eventing";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { FocusType, searchActions, SearchMsg, SearchPayload } from "../../store/search-slice";
import { getSearchCmdDispatch } from "../../adapters/FeedAdapter";

export const contentSearchFeatureFlagKey = "allowContentSearch";

const contentSearchFeatureFlag = createLSFeatureFlag(contentSearchFeatureFlagKey, true, true);


export type ContentSearchProps = {
    // focus: System.PropWrapper<boolean>
    cardClassName: string
    categories?: string[]
    // onSearch: SearchInvokeAsync
}

// nice to have: server flag forcing content search off
export const ContentSearch = ({ cardClassName }: ContentSearchProps) => {

    // const [lastSearch, setLastSearch] = useState<string>("");
    const userSearchState = useSelector((state: RootState) => state.search);
    const [searchText, setSearchText] = useState<string>(userSearchState.currentShownKey ?? "");
    // const [awaitingSearchResult, setAwaitingSearchResult] = useState<boolean>(false);
    // const wasLongSearchOnSmallDevice = useRef(false);
    const input = useRef<HTMLInputElement | undefined>(undefined);

    let isSmallDevice = queryKnownMatchMedia('isSmallDevice') ?? false;
    let dispatch = useDispatch();
    let cmdDispatch = getSearchCmdDispatch(dispatch);
    // console.log('rerendering onSearch')

    // WARN: this may be undermining the search loading indicator and wait
    // also this may be getting aborted
    // if the content search decides it needs to rerender while this is in flight
    let onSearchClick = async (searchText: string) => {
        console.log('searching for', searchText);
        // setAwaitingSearchResult(true);
        let msg: SearchMsg = {
            type: 'PostsLoadRequestedMsg',
            searchInput: searchText
        }
        dispatch(searchActions.update({ msg, cmdDispatch }));
    };

    let updateFocusCb = useCallback((userRequest: boolean, focusType: FocusType) => {
        let msg: SearchMsg = {
            type: 'RefocusRequestedMsg',
            key: userSearchState.currentShownKey,
            userRequest,
            focusType
        }
        let s: SearchPayload = { msg, cmdDispatch }
        dispatch(searchActions.update(s));

    }, [userSearchState.currentShownKey, dispatch])

    // this was set as a callback with no dependencies, if this starts acting strange, maybe set it back
    // let refocus = debounce(() => { console.log('refocus'); setIsFocus(true); });

    let showContentSearch = contentSearchFeatureFlag.getter();

    if (showContentSearch !== true) {
        return (<div />);
    }

    // if the search is empty and is not 1-2 chars
    let hasValidSearch = searchText === "" || (searchText && searchText.length && (searchText.length > 2 || false));
    let lastSearch = userSearchState.postItemsSearch?.key;
    let hasValidLastSearch = !System.String.isNullOrEmpty(lastSearch);

    let awaitingSearchResult = userSearchState.inFlightSearch !== undefined;

    if (!userSearchState.searchFocused)
        return (
            <IconButton type="button" sx={{ p: '10px' }}
                style={{ color: "#fff" }}
                aria-label="search" onClick={() => updateFocusCb(true, 'WantsFocus')}>
                <SearchIcon />
            </IconButton>
        );

    let searchDisabled: boolean = awaitingSearchResult || lastSearch === searchText || !hasValidSearch || (!hasValidLastSearch && searchText === "") || false;

    let onClearClick = () => {
        setSearchText("");
        let msg: SearchMsg = {
            type: 'UserSearchClearMsg'
        }
        dispatch(searchActions.update({ msg, cmdDispatch }));
    }

    console.log('ContentSearch', JSON.stringify({ searchText, searchDisabled, isFocus: userSearchState.searchFocused, awaitingSearchResult }))
    // if the input type changed from input to text area



    let compensateForLongSearch: boolean = (isSmallDevice && searchText && searchText.length > 30) || false;

    // this assumption fixes the issue with the textbox being off screen 
    // otherwise the user on a small device would no longer be able to see what they are typing on long searches
    // this may be a bad value on smaller devices than tested
    let maxPaperWidth = userSearchState.searchFocused ? (isSmallDevice ? 320 : 505) : 350;
    // console.log('CS', { awaitingSearchResult, lastSearch, searchText, hasValidSearch, hasValidLastSearch, searchDisabled });
    // https://mui.com/material-ui/react-text-field/
    return ( // using component form to capture enter key
        <Paper
            component={'form'}
            onSubmit={withPreventDefault(() => !searchDisabled ? onSearchClick(searchText) : {})}
            elevation={14}
            // onClick={() => setTimeout(() => updateFocusCb(true, 'WantsRefocus'), 150)}
            className={cardClassName}
            sx={{ p: '2px 4px', height: userSearchState.searchFocused ? '46px' : isSmallDevice ? undefined : '40px', display: 'flex', alignItems: 'center', width: maxPaperWidth, marginTop: '5px', marginBottom: '5px' }}
        >
            {awaitingSearchResult ? <div>loading...</div> :
                <React.Fragment>
                    {/* <IconButton disabled={searchText === ""} title={"Clear Search"} tabIndex={4} type="button" sx={{ p: '10px' }} onClick={onClearClick} aria-label="search">
                        <XIcon />
                    </IconButton> */}
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search OneView Home"
                        tabIndex={1}
                        // aria-describedby={shouldShowHelper ? 'my-helper-text' : ''}
                        inputProps={{ 'aria-label': 'search oneView home' }}
                        // size={compensateForLongSearch ? 'medium' : 'small'}
                        // multiline={compensateForLongSearch}
                        // rows={2}
                        fullWidth={compensateForLongSearch}
                        // minRows={2}
                        inputRef={input}
                        value={searchText}
                        // autoFocus={userSearchState.searchFocused}
                        autoFocus
                        onFocus={() => updateFocusCb(true, 'WantsFocus')}
                        // set timeout because the search click will not bubble if this blur forces a rerender
                        // before we get the click handler started
                        onChange={(e) => (setSearchText(e.target.value))}
                    />
                    {/* {shouldShowHelper ?
                        <FormHelperText id="my-helper-text" onClick={() => refocus(true)} >'{lastSearch}' Search Results</FormHelperText > : ''} */}
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled={searchDisabled} tabIndex={2} onClick={() => onSearchClick(searchText)}>
                        <SearchIcon />
                    </IconButton>
                    <IconButton title={"Exit Search Mode"} tabIndex={3} type="button" sx={{ p: '10px' }} onClick={() => { onClearClick(); updateFocusCb(true, 'WantsUnfocus'); }} aria-label="search">
                        <XIcon />
                    </IconButton>
                </React.Fragment>
            }
        </Paper>
    );
}